import React from "react";
import "./commpage.css";
import { BiArrowBack } from "react-icons/bi";
import { motion } from "framer-motion";

const CommPage = ({ backToHome }) => {
  const commPageanimate = {
    hidden: {
      x: "100vw",
    },
    visible: {
      x: 0,
      transition: { duration: 0.5 },
    },
    exit: {
      x: "100vw",
      transition: { ease: "easeInOut" },
    },
  };

  const microsoftLink = () => {
    window.open("https://www.microsoftsupplier.com", "_blank");
  };
  const shieldCannon = () => {
    window.open("https://www.shieldcannon.com/", "_blank");
  };
  const staalixtLink = () => {
    window.open("https://www.staalix.nl", "_blank");
  };
  const adobeLink = () => {
    window.open("https://www.adobesupplier.com", "_blank");
  };
  const autodeskLink = () => {
    window.open("https://www.autodesksupplier.com", "_blank");
  };

  return (
    <motion.div
      className="commpage container mt-4 mb-4 p-3"
      variants={commPageanimate}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <BiArrowBack onClick={backToHome} className="back-arrow" size={40} />
      <div className="container py-5">
        <div className="row   justify-content-center">
          <div className="col-md-3 mb-0">
            <button
              onClick={microsoftLink}
              className="button  button--moema"
            >
              Microsoft Supplier
            </button>
          </div>
          <div className="col-md-3 mb-0">
            <button
              onClick={staalixtLink}
              className="  button button--moema w-100"
            >
              Staalix
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 mb-0">
            <button
              onClick={adobeLink}
              className=" button button--moema w-100"
            >
              Adobe Supplier
            </button>
          </div>
          <div className="col-md-3 mb-0">
            <button
              onClick={autodeskLink}
              className="  button button--moema w-100"
            >
              Autodesk Supplier
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 mb-0">
            <button
              onClick={shieldCannon}
              className=" button button--moema w-100"
            >
              Shield cannon
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CommPage;
