import React from 'react'
import "./footer.css"
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='container text-center footer'>
        <p> &copy; 2016 - {currentYear} Sertic  </p>
    </div>
  )
}

export default Footer